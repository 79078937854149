import { defineStore } from 'pinia'
import type { PropertyType, Address, Building, Property } from '@/types/case'

export const usePropertyStore = defineStore('property', () => {
  const address = ref<Address>()
  const type = ref<PropertyType>()
  const buildings = ref<Building[]>([])
  const mainBuilding = computed<Building | undefined>(() =>
    buildings.value.find((building) => building.mainBuilding)
  )

  /**
   * Populate the store
   * @param property The `Property` object to populate the store with
   */
  function populate(property: Property) {
    address.value = property.address
    type.value = property.type
    buildings.value = property.buildings
  }

  return {
    address,
    type,
    buildings,
    mainBuilding,
    populate,
  }
})

export type PropertyStore = ReturnType<typeof usePropertyStore>
