import { default as elinstallationsrapporto7oGog3apSMeta } from "/workspace/pages/[clientId]/[caseId]/elinstallationsrapport.vue?macro=true";
import { default as indexzr7r9DpiNaMeta } from "/workspace/pages/[clientId]/[caseId]/index.vue?macro=true";
import { default as overblik1V2drSphKWMeta } from "/workspace/pages/[clientId]/[caseId]/overblik.vue?macro=true";
import { default as pdfvtl7hxcTIFMeta } from "/workspace/pages/[clientId]/[caseId]/pdf.vue?macro=true";
import { default as indexJDPl5EK9AVMeta } from "/workspace/pages/[clientId]/[caseId]/potentiale/index.vue?macro=true";
import { default as miljoemaessig_45kvalitetOWMsJAoyHjMeta } from "/workspace/pages/[clientId]/[caseId]/potentiale/miljoemaessig-kvalitet.vue?macro=true";
import { default as oekonomisk_45kvalitetgdu3ImcloyMeta } from "/workspace/pages/[clientId]/[caseId]/potentiale/oekonomisk-kvalitet.vue?macro=true";
import { default as social_45kvalitet1Dtq7YjcvFMeta } from "/workspace/pages/[clientId]/[caseId]/potentiale/social-kvalitet.vue?macro=true";
import { default as potentiale2kTy39XDMUMeta } from "/workspace/pages/[clientId]/[caseId]/potentiale.vue?macro=true";
import { default as tilstandsrapport5Jk8FYhKsKMeta } from "/workspace/pages/[clientId]/[caseId]/tilstandsrapport.vue?macro=true";
import { default as errorey2ervqRNaMeta } from "/workspace/pages/error.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
export default [
  {
    name: "clientId-caseId-elinstallationsrapport",
    path: "/:clientId()/:caseId()/elinstallationsrapport",
    component: () => import("/workspace/pages/[clientId]/[caseId]/elinstallationsrapport.vue").then(m => m.default || m)
  },
  {
    name: "clientId-caseId",
    path: "/:clientId()/:caseId()",
    meta: indexzr7r9DpiNaMeta || {},
    component: () => import("/workspace/pages/[clientId]/[caseId]/index.vue").then(m => m.default || m)
  },
  {
    name: "clientId-caseId-overblik",
    path: "/:clientId()/:caseId()/overblik",
    component: () => import("/workspace/pages/[clientId]/[caseId]/overblik.vue").then(m => m.default || m)
  },
  {
    name: "clientId-caseId-pdf",
    path: "/:clientId()/:caseId()/pdf",
    component: () => import("/workspace/pages/[clientId]/[caseId]/pdf.vue").then(m => m.default || m)
  },
  {
    name: potentiale2kTy39XDMUMeta?.name,
    path: "/:clientId()/:caseId()/potentiale",
    component: () => import("/workspace/pages/[clientId]/[caseId]/potentiale.vue").then(m => m.default || m),
    children: [
  {
    name: "clientId-caseId-potentiale",
    path: "",
    meta: indexJDPl5EK9AVMeta || {},
    component: () => import("/workspace/pages/[clientId]/[caseId]/potentiale/index.vue").then(m => m.default || m)
  },
  {
    name: "clientId-caseId-potentiale-miljoemaessig-kvalitet",
    path: "miljoemaessig-kvalitet",
    component: () => import("/workspace/pages/[clientId]/[caseId]/potentiale/miljoemaessig-kvalitet.vue").then(m => m.default || m)
  },
  {
    name: "clientId-caseId-potentiale-oekonomisk-kvalitet",
    path: "oekonomisk-kvalitet",
    component: () => import("/workspace/pages/[clientId]/[caseId]/potentiale/oekonomisk-kvalitet.vue").then(m => m.default || m)
  },
  {
    name: "clientId-caseId-potentiale-social-kvalitet",
    path: "social-kvalitet",
    component: () => import("/workspace/pages/[clientId]/[caseId]/potentiale/social-kvalitet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "clientId-caseId-tilstandsrapport",
    path: "/:clientId()/:caseId()/tilstandsrapport",
    component: () => import("/workspace/pages/[clientId]/[caseId]/tilstandsrapport.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    meta: errorey2ervqRNaMeta || {},
    component: () => import("/workspace/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index9S03gOyIVcMeta || {},
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  }
]