import { defineStore } from 'pinia'
import type { Price } from '@/types/case'
import type { Maybe } from '@/types/util'

/**
 * Store for price adjustments and exclusions on damages and issues
 */
export const useAdjustmentStore = defineStore(
  'adjustment',
  () => {
    // Store adjustments as a map of item IDs to adjustments
    const adjustments = ref<Record<string, Maybe<Price>>>({})
    // Store a map of item IDs that should be excluded from the total price
    const excluded = ref<Record<string, boolean>>({})

    /**
     * Set a price adjustment for a specific item
     * If an adjustment does not exist, it will be created using fallback values from the item
     * @param id The id to adjust
     * @param adjustedPrice The adjusted price (can be partial)
     */
    function set(id: string, adjustedPrice: Price) {
      adjustments.value[id] = adjustedPrice
    }

    /**
     * Remove a price adjustment for a specific item
     * @param id The item id to remove the adjustment for
     */
    function remove(id: string) {
      delete adjustments.value[id]
    }

    /**
     * Exclude an item from the total price
     * @param id ID of the item to exclude
     */
    function excludeItem(id: string) {
      excluded.value[id] = true
    }

    /**
     * Include an item in the total price
     * @param id ID of the item to include
     */
    function includeItem(id: string) {
      delete excluded.value[id]
    }

    return {
      adjustments,
      excluded,
      set,
      remove,
      includeItem,
      excludeItem,
    }
  },
  persistStore()
)
