import { defineStore } from 'pinia'
import type {
  Case,
  Co2Emission,
  EconomicalQuality,
  EnergyConsumption,
  EnergyLabel,
  SocialQuality,
} from '@/types/case'

/**
 * Store for the qualities of a case: economical, social, and environmental
 */
export const useQualitiesStore = defineStore('qualities', () => {
  const { id } = storeToRefs(useCaseStore())
  const { selectedSuggestionsResult } = storeToRefs(
    useSuggestionAdjustmentStore()
  )
  const economicalQualities = ref<EconomicalQuality[]>()
  const socialQualities = ref<SocialQuality[]>()
  // Environmental qualities are represented by the following three properties
  const energyConsumption = ref<EnergyConsumption | null>(null)
  const co2Emission = ref<Co2Emission | null>(null)
  const energyLabels = ref<EnergyLabel[] | null>(null)

  /**
   * Populate the store
   * @param currentCase The case to populate the store with
   */
  function populate(currentCase: Case) {
    economicalQualities.value = currentCase.property.economicalQualities
    socialQualities.value = currentCase.property.socialQualities
    energyConsumption.value = currentCase.energyConsumption
    co2Emission.value = currentCase.co2Emission
    energyLabels.value = currentCase.energyLabels
  }
  const potentialEnergyLabel = computed(() => {
    const potentialEnergyMark = energyLabels.value?.find(
      ({ energyLabelType }) => energyLabelType === 'Profitable'
    )

    return selectedSuggestionsResult.value &&
      selectedSuggestionsResult.value?.id?.toLowerCase() ===
        id?.value?.toLowerCase()
      ? selectedSuggestionsResult.value
      : potentialEnergyMark
  })
  return {
    economicalQualities,
    socialQualities,
    energyConsumption,
    co2Emission,
    energyLabels,
    populate,
    potentialEnergyLabel,
  }
})
export type QualitiesStore = ReturnType<typeof useQualitiesStore>
