import { defineStore } from 'pinia'
import type {
  ElectricityReport,
  Issue,
  IssueSeverity,
  Rating,
} from '@/types/case'

/**
 * Store for the electricity report
 */
export const useElectricityReportStore = defineStore(
  'electricityReport',
  () => {
    const adjustmentStore = useAdjustmentStore()
    const { includeItem, excludeItem } = adjustmentStore
    const { adjustments, excluded } = storeToRefs(adjustmentStore)

    // Electricity report data
    const serialNumber = ref<string | null>()
    const overallRating = ref<Rating | null>()
    const inspectionDate = ref<Date | null>()
    const issues = ref<Issue[] | null>()

    // Issues with price adjustments included in the report
    const issuesForReport = computed(
      () =>
        issues.value
          ?.filter((issue) => !excluded.value[issue.id])
          .map((issue) => {
            const adjustedPrice = adjustments.value[issue.id]
            return adjustedPrice ? { ...issue, price: adjustedPrice } : issue
          })
    )

    // Issues with price adjustments included in the calculation
    const severitiesForCalculation: IssueSeverity[] = [
      'RiskOfFire',
      'RiskOfElectrocution',
    ]
    const issuesForCalculation = computed(
      () =>
        issuesForReport.value?.filter((issue) =>
          severitiesForCalculation.includes(issue.severity)
        )
    )

    // Map of issue severities to the number of issues in each category
    const issueCountBySeverity = computed(() => {
      return (
        issues?.value?.reduce(
          (acc, damage) => {
            const severity = damage.severity
            acc[severity] = acc[severity] ? acc[severity] + 1 : 1
            return acc
          },
          {} as Record<IssueSeverity, number>
        ) || null
      )
    })

    // Check if any issues of severity `RiskOfFire` are included
    const areRiskOfFireIssuesIncluded = computed(
      () =>
        issues.value?.some(
          (issue) =>
            issue.severity === 'RiskOfFire' && !excluded.value[issue.id]
        ) ?? false
    )

    // Check if any issues of severity `RiskOfElectrocution` are included
    const areRiskOfElectrocutionIssuesIncluded = computed(
      () =>
        issues.value?.some(
          (damage) =>
            damage.severity === 'RiskOfElectrocution' &&
            !excluded.value[damage.id]
        ) ?? false
    )

    /**
     * Populate the store
     * @param electricityReport The electricity report object to populate the store with
     */
    function populate(electricityReport: ElectricityReport | null) {
      if (electricityReport === null) {
        // Clear the store if the electricity report is null
        serialNumber.value = null
        overallRating.value = null
        inspectionDate.value = null
        issues.value = null
      } else {
        serialNumber.value = electricityReport.serialNumber ?? '-'
        overallRating.value = electricityReport.overallRating
        inspectionDate.value = electricityReport.inspectionDate
        issues.value = electricityReport.issues ?? []
      }
    }

    /**
     * Include or exclude all issues of a specific severity
     * @param severity The severity to include
     * @param include Whether to include or exclude the issues
     */
    function toggleAllOfSeverity(severity: IssueSeverity, include: boolean) {
      issues.value?.forEach((issue) => {
        if (issue.severity === severity) {
          include ? includeItem(issue.id) : excludeItem(issue.id)
        }
      })
    }

    return {
      serialNumber,
      overallRating,
      inspectionDate,
      issues,
      issuesForReport,
      issuesForCalculation,
      issueCountBySeverity,
      areRiskOfFireIssuesIncluded,
      areRiskOfElectrocutionIssuesIncluded,
      populate,
      toggleAllOfSeverity,
    }
  }
)
export type ElectricityReportStore = ReturnType<
  typeof useElectricityReportStore
>
