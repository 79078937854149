import { z } from 'zod'
import { Case } from '@/schemas/case'

export default defineNuxtRouteMiddleware(async (to) => {
  // Always allow navigation to root path

  if (to.fullPath === '/' || to.fullPath.startsWith('/error')) return

  // If a case ID is already loaded, allow navigation
  const caseStore = useCaseStore()
  const { id } = storeToRefs(caseStore)
  if (id.value) return

  // Otherwise, fetch data for the case from API
  try {
    const runtimeConfig = useRuntimeConfig()

    const propertyStore = usePropertyStore()
    const electricityReport = useElectricityReportStore()
    const conditionReport = useConditionReportStore()
    const qualities = useQualitiesStore()
    const suggestionGroups = useSuggestionStore()

    const caseId = z.string().uuid().parse(to.params.caseId)
    const clientId = z.string().parse(to.params.clientId)
    // Fetch case from API
    const { data: casePayload } = await useFetch(
      `${runtimeConfig.public.API_BASE_URL}/api/v${runtimeConfig.public.API_VERSION}/clients/${clientId}/cases/${caseId}`,
      {
        headers: {
          'X-API-KEY': runtimeConfig.public.API_KEY,
        },
      }
    )

    const currentCase = Case.parse(casePayload.value)

    // Populate stores
    propertyStore.populate(currentCase.property)
    electricityReport.populate(currentCase.property.electricityReport)
    conditionReport.populate(currentCase.property.conditionReport)
    qualities.populate(currentCase)
    suggestionGroups.populate(currentCase.suggestionGroups)

    // Store the case id
    caseStore.loadCase(currentCase)
    // Navigate to the requested path
    return
  } catch (error) {
    // Log the error to Bugsnag
    if (error instanceof Error) {
      useBugsnag().notify(error, (event) => {
        event.context = 'Data fetching'
        event.addMetadata('Request', {
          // Include the case ID in the metadata
          caseId: to.params.caseId,
          clientId: to.params.clientId,
        })
      })
    }
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred'

    if (process.env.RELEASE_STAGE !== 'production') {
      return navigateTo({
        path: '/error',
        query: { error: errorMessage ? errorMessage : [] },
      })
    }
    return navigateTo({ path: '/' })
  }
})
