/**
 * Generate a persist store configuration for Pinia:
 * Cookies with `sameSite: strict` and `maxAge: 1 year`
 * @see https://prazdevs.github.io/pinia-plugin-persistedstate/frameworks/nuxt-3.html#cookieswithoptions
 * @returns Pinia persist store configuration for use in `defineStore`
 */
export const persistStore = () => ({
  persist: {
    storage: persistedState.cookiesWithOptions({
      sameSite: 'strict',
      maxAge: 60 * 60 * 24 * 365, // Store for 1 year
    }),
  },
})
