import { defineStore } from 'pinia'
import type {
  ConditionReport,
  Damage,
  DamageSeverity,
  Rating,
} from '@/types/case'

/**
 * Store for the condition report
 */
export const useConditionReportStore = defineStore('conditionReport', () => {
  // Adjustment store
  const adjustmentStore = useAdjustmentStore()
  const { includeItem, excludeItem } = adjustmentStore
  const { adjustments, excluded } = storeToRefs(adjustmentStore)

  // Condition report data
  const serialNumber = ref<string>()
  const overallRating = ref<Rating>()
  const inspectionDate = ref<Date>()
  const damages = ref<Damage[]>()

  // Damages with price adjustments included in the report
  const damagesForReport = computed(
    () =>
      damages.value
        ?.filter((damage) => !excluded.value[damage.id])
        .map((issue) => {
          const adjustedPrice = adjustments.value[issue.id]
          return adjustedPrice ? { ...issue, price: adjustedPrice } : issue
        })
  )

  // Damages with price adjustments included in the calculation
  const severitiesForCalculation: DamageSeverity[] = ['Critical', 'Serious']
  const damagesForCalculation = computed(
    () =>
      damagesForReport.value?.filter((damage) =>
        severitiesForCalculation.includes(damage.severity)
      )
  )

  // Map of damage severities to the number of damages in each category
  const damageCountBySeverity = computed(
    () =>
      damages.value?.reduce(
        (acc, damage) => {
          const severity = damage.severity
          acc[severity] = acc[severity] ? acc[severity] + 1 : 1
          return acc
        },
        {} as Record<DamageSeverity, number>
      )
  )

  // Check if any damages of severity `Critical` are included
  const areCriticalDamagesIncluded = computed(
    () =>
      damages.value?.some(
        (damage) => damage.severity === 'Critical' && !excluded.value[damage.id]
      ) ?? false
  )

  // Check if any damages of severity `Serious` are included
  const areSeriousDamagesIncluded = computed(
    () =>
      damages.value?.some(
        (damage) => damage.severity === 'Serious' && !excluded.value[damage.id]
      ) ?? false
  )

  /**
   * Populate the store
   * @param conditionReport The `Issue` object to populate the store with
   */
  function populate(conditionReport: ConditionReport) {
    serialNumber.value = conditionReport.serialNumber ?? '-'
    overallRating.value = conditionReport.overallRating
    inspectionDate.value = conditionReport.inspectionDate
    damages.value = conditionReport.damages ?? []
  }

  /**
   * Include or exclude all damages of a specific severity
   * @param severity The severity to include
   * @param include Whether to include or exclude the damages
   */
  function toggleAllOfSeverity(severity: DamageSeverity, include: boolean) {
    damages.value?.forEach((damage) => {
      if (damage.severity === severity) {
        include ? includeItem(damage.id) : excludeItem(damage.id)
      }
    })
  }

  return {
    serialNumber,
    overallRating,
    inspectionDate,
    damages,
    damagesForReport,
    damagesForCalculation,
    damageCountBySeverity,
    areCriticalDamagesIncluded,
    areSeriousDamagesIncluded,
    populate,
    toggleAllOfSeverity,
  }
})
export type ConditionReportStore = ReturnType<typeof useConditionReportStore>
